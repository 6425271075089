@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
	margin: 0;
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.ant-btn-icon {
	margin-bottom: 0.4vh;
}

.input-group-text {
	background-color: white;
	cursor: pointer;
	color: #4e4e4e;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
}

.form-check-input:checked {
	background-color: #225fb0;
	border-color: #225fb0;
}

.form-check-label {
	color: #4e4e4e;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
}

.mb-05 {
	margin-bottom: 0.15rem !important;
}

svg {
	vertical-align: baseline;
}

body.modal-open> :not(.modal) {
	/*filter: blur(3px);*/
}

.cursor-pinter {
	cursor: pointer;
}

.bd-4 {
	border-radius: 4px;
}

.ant-pagination .ant-pagination-item {
	font-family: 'Inter', sans-serif !important;
}

.w-30 {
	width: 30%;
}

.w-60 {
	width: 60%;
}

.w-70 {
	width: 70%;
}

.text-red {
	color: #a5492d;
}

.w-25 {
	width: 25%;
}

.w-10 {
	width: 10%;
}

.w-15 {
	width: 15%;
}

.w-20 {
	width: 20%;
}

.w-25 {
	width: 25%;
}

.w-30 {
	width: 30%;
}

.w-40 {
	width: 40%;
}

.w-95 {
	width: 95%;
}

.bg-danger-100 {
	background-color: #eaa2a4;
}

.bg-danger-66 {
	background-color: #efbabc;
}

.bg-danger-33 {
	background-color: #f5d4d5;
}

.bg-neutral {
	background-color: #d9d9d9;
}

.bg-success-100 {
	background-color: #a6d0a6;
}

.bg-success-66 {
	background-color: #bddbbd;
}

.bg-success-33 {
	background-color: #cde3cd;
}

.bg-blue-33 {
	background-color: #ceedf8;
}

.rounded-left-only {
	border-radius: 4px 0 0 4px;
}

.rounded-right-only {
	border-radius: 0 4px 4px 0;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
	background-color: #f5f5f5;
}

.ant-picker-input>input {
	font-size: 10px !important;
	font-weight: 600;
}

.text-green {
	color: #107c10;
}

.text-red {
	color: #d13438;
}

.text-neutral {
	color: #4e4e4e;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
	padding: 0;
}

.fs-24 {
	font-size: 24px !important;
}

.fs-20 {
	font-size: 20px !important;
}

.fs-10 {
	font-size: 10px;
}

.fs-12 {
	font-size: 12px !important;
}

.fs-14 {
	font-size: 14px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.bg-blue {
	background-color: #dfedf7;
}

.fw-600 {
	font-weight: 600;
}

.bg-lightgrey {
	background-color: #f5f5f5;
}

.bg-orange {
	background-color: #d47645;
}

.bg-lightblue {
	background-color: #e0ecfc;
}

.bg-darkblue {
	background-color: #1f78b4;
}

.text-darkblue {
	color: #1f78b4;
}

.bg-danger-pink {
	background-color: #ffdfdf;
}

.bg-darkerblue {
	background-color: #225FB0;
}

.scrolling-wrapper {
	overflow-y: hidden;
	white-space: nowrap;
}

.table-scrollable {
	overflow-x: auto;
	width: 100%;
}

.tooltip-line {
	display: flex;
	gap: 1.5vw;
	justify-content: center;

	border-radius: 4px;
	border: 1px solid #225fb0;
	background: #fff;
	padding: 8px;
	font-size: 10px;
}

.cursor-pointer {
	cursor: pointer;
}

.h-23 {
	height: 23%;
}

.fw-400 {
	font-weight: 400;
}

.px-20 {
	padding-right: 20px;
	padding-left: 20px;
}

.fw-700 {
	font-weight: 700;
}

.mb-15 {
	margin-bottom: 15px;
}

.mt-15 {
	margin-top: 15px;
}

.bg-grey {
	background-color: #dcdfe7;
}

.disabled-dropdown {
	background-color: #dcdcdc;
	color: #b1b1b1;
}

.hover-white:hover {
	background-color: white;
	color: #225FB0 !important;
	border: 1px solid #225FB0;
}

.hover-blue:hover {
	background-color: #e0ecfc;
	/*cursor: pointer;*/
}

.table-fixed {
	table-layout: fixed;
	padding: 0.1vw;
}

.company-stock-code {
	border-radius: 4px;
	background: #225fb0;
	padding: 4px 8px;
	color: #fff;
}

.standard-code {
	border-radius: 4px;
	padding: 4px 8px;
}

.standard-padding {
	padding: 4px 8px;
}

.mh-100 {
	min-height: 100% !important;
}

.trim-text {
	white-space: nowrap;
	overflow: hidden;
	/*text-overflow: ellipsis;*/
}

.row-selected {
	background-color: #e0ecfc;
}

.cursor-none {
	cursor: not-allowed;
}

.border-darkblue {
	border: 1px solid #1f78b4;
}

.ant-select-selector {
	border-radius: 4px;
	background-color: #e0ecfc !important;
	border: none !important;
}

.ant-select-selection-item {
	color: #000;
	font-style: normal;
	line-height: normal;
	font-family: 'Inter', sans-serif !important;
}

.line {
	fill: none;
	stroke: #ffab00;
	stroke-width: 1.5;
}

.ant-select-selector {
	background-color: #f5f5f5 !important;
}

.ant-select-selection-placeholder {
	color: #4e4e4e !important;
	font-weight: 500;
}

.multi-line-tooltip {
	border-radius: 4px;
	border: 1px solid #225fb0;
	background: #fff;
}

.swipe-effect-right {
	-webkit-animation: slideRight 0.3s forwards;
	-moz-animation: slideRight 0.3s forwards;
	animation: slideRight 0.3s forwards;
}

@-webkit-keyframes slideRight {
	0% {
		transform: translateX(900px);
	}

	100% {
		transform: translateX(0);
	}
}

@-moz-keyframes slideRight {
	0% {
		transform: translateX(900px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes slideRight {
	0% {
		transform: translateX(900px);
	}

	100% {
		transform: translateX(0);
	}
}

.list-data {
	border-bottom: 1px solid #E0E0E0;
	border-top: 1px solid #E0E0E0;
}

.table-hover-blue:hover>td {
	background-color: #e0ecfc !important;
	cursor: pointer;
}

.swipe-effect-left {
	-webkit-animation: slideLeft 0.3s forwards;
	-moz-animation: slideLeft 0.3s forwards;
	animation: slideLeft 0.3s forwards;
}

@-webkit-keyframes slideLeft {
	0% {
		transform: translateX(-900px);
	}

	100% {
		transform: translateX(0);
	}
}

@-moz-keyframes slideLeft {
	0% {
		transform: translateX(-900px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes slideLeft {
	0% {
		transform: translateX(-900px);
	}

	100% {
		transform: translateX(0);
	}
}

.margin-right-2 {
	margin-right: 2px;
}

.margin-right-8 {
	margin-right: 8px;
}

.margin-left-2 {
	margin-left: 2px;
}

.margin-top-8 {
	margin-top: 8px;
}

.margin-top-2 {
	margin-top: 2px;
}

.margin-left-8 {
	margin-left: 8px;
}

.margin-top-4 {
	margin-top: 4px;
}